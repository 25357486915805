@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"

.transcriptions
    &__header
        &--columns
            display: flex
            justify-content: space-between
            align-items: baseline
            +mobile
                flex-direction: column

            & a 
                margin-left: auto

        &--title
            margin: 60px 0 15px
            font-size: 1.75rem
            font-weight: bold
            +kontrast
                color: $kontrast-kolor3
        
        &--search-result
            font-size: 1.125rem

        &--search-number
            font-size: 1rem

        &--transcr-number
            font-weight: normal

        &--description
            font-size: 1rem
            +kontrast
                color: $kontrast-kolor3

    &__transcr-container
        margin: 40px auto
        width: 100%
        display: grid
        grid-template-columns: 1fr
        grid-row-gap: 20px

    &__error
        padding-top: 3rem
        padding-left: 3rem
        padding-bottom: 10rem
        font-weight: bold
        font-size: 1.125rem
        display: flex
        background-color: white
        margin-bottom: 200px
        +kontrast
            color: $kontrast-kolor3
            background-color: transparent
        img
            +kontrast
                filter: contrast(100) invert(100%)


    &__search
        margin-top: 30px
        width: 550px
        max-width: 100%
        margin-left: auto
        margin-right: 0
        +mobile
            margin-top: 20px

    &__select
        margin-top: 40px
        position: relative
        z-index: 400
        width: 350px
        +mobile
            margin-top: 0
            width: 100%
    &__pagination-wrapper
        position: relative
        z-index: 0
        // margin-top: -120px
        +mobile
            margin-top: -40px
    &__no-pagination-wrapper
        margin-top: 50px

.container
    //& :global(.pagination-top)
        justify-content: left
        align-items: baseline
        display: flex
        +kontrast
            color: $kontrast-kolor3
            background-color: $kontrast-kolor2

        &--left
            margin-left: auto
            float: right
            align-items: baseline

    //& :global(.pagination__btn)
        color: #ffffff
        background-color: #005CA9
        border: 2px solid #005CA9
        border-radius: 0
        +kontrast
            color: $kontrast-kolor2
            background-color: $kontrast-kolor1
        +mobile
            width: 140px
            padding: 5px 0
            font-size: 0.8125rem
        &:hover
            background-color: #ffffff
            color: #005CA9
            +kontrast
                color: $kontrast-kolor1
                background-color: $kontrast-kolor2
        &--next::after
            border-top: 0.3em solid transparent
            border-right: 0.3em solid transparent
            border-left: 0.3em solid
            margin-left: 10px 
            color: #ffffff
            +kontrast
                color: $kontrast-kolor2
                &:hover
                    color: $kontrast-kolor1
        &--next:hover::after
                color: #005CA9
                +kontrast
                    color: $kontrast-kolor1
        &--prev::before
            border-top: 0.3em solid transparent
            border-right: 0.3em solid
            border-left: 0.3em solid transparent
            color: #ffffff
            margin-left: 0px
            +kontrast
                color: $kontrast-kolor2
                &:hover
                    color: $kontrast-kolor1
        &--prev:hover::before
                color: #005CA9
                +kontrast
                    color: $kontrast-kolor1

    & :global(.dropdown-menu)
        +kontrast
            color: $kontrast-kolor3
            background-color: $kontrast-kolor2
    & :global(.pagination-bottom__item )
        +kontrast
            color: $kontrast-kolor1
    & :global(.pagination-bottom__item--disabled)
        +kontrast
            color: $kontrast-kolor3
    & :global(.pagination__input)
        +kontrast
            background-color: $kontrast-kolor1