.slick-prev
    left: 25px
    z-index: 999

.slick-next
    right: 25px
    z-index: 999

.slick-prev:before, .slick-next:before
    opacity: 1
    color: grey
