@import "@Styles/_font-colors.sass"
@import "@Styles/_mixins.sass"
@import "@Styles/_spinner.sass"
@import "@Styles/_custom-bootstrap.sass"
@import "@Styles/_icons.sass"
@import "@Styles/_index.sass"
@import "@Styles/_draft-editor.sass"
@import "@Styles/_text.sass"
@import "@Styles/_block.sass"
@import "@Styles/_auth.sass"
@import "@Styles/_modal.sass"
@import "@Styles/viewer/_viewer.sass"
@import "@Styles/viewer/_viewer-header.sass"
@import "@Styles/viewer/_viewer-footer.sass"
@import "@Styles/viewer/_verse.sass"
@import "@Styles/viewer/_osd.sass"
@import "@Styles/_transcription.sass"
@import "@Styles/_contrast.sass"
@import "@Styles/_font-size.sass"
@import "@Styles/_dropdown.sass"
@import "@Styles/_header.sass"
@import "@Styles/_pagination.sass"
.hide-toolbar 
    display: none !important

.public-DraftStyleDefault-block
    margin: 8px 0 !important

