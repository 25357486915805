@import "font-colors"
.contrast
    background: $kontrast-kolor2

    a
        color: $kontrast-kolor1

    a.btn:not(.btn-primary):hover
        color: $kontrast-kolor2
        background-color: $kontrast-kolor1


    .btn
        color: $kontrast-kolor1
        background-color: $kontrast-kolor2
        &:hover
            text-decoration: none

        &-primary
            &:hover, &:focus
                text-decoration: none
        &-secondary
            &:hover, &:focus
                text-decoration: none
        &-third
            &:hover, &:focus
                text-decoration: none

    .btn-link
        background-color: transparent
        border-color: $kontrast-kolor1
        color: $kontrast-kolor1
        &:hover,
        &:focus,
        &:active
            background-color: transparent
            color: $kontrast-kolor1
            text-decoration: underline

    .btn-primary
        box-shadow: 0 0 0 2px $kontrast-kolor1

    .btn-primary:hover
        box-shadow: 0 0 0 2px $kontrast-kolor1

    .btn-secondary
        box-shadow: 0 0 0 2px $kontrast-kolor1

    .btn-secondary:hover
        box-shadow: 0 0 0 2px $kontrast-kolor1

    .btn-group
        & > a.btn.disabled
            background-color: $kontrast-kolor1
            color: $kontrast-kolor5
            border-color: $kontrast-kolor1

    .dropdown-toggle.btn
        &:active,&:focus
            text-decoration: none

    .permissions

        .dropdown.show
            & > .btn-primary.dropdown-toggle
                background: $kontrast-kolor1
                color: $kontrast-kolor6

                &::after
                    color: $kontrast-kolor6
        
        .dropdown-item
            color: $kontrast-kolor3

            &:hover, &:focus
                color: $kontrast-kolor6

    .transcription
        &-new
            &__title--container
                color: $kontrast-kolor3
            &__subtitle
                color: $kontrast-kolor3
            &__form
                &--terms
                    color: $kontrast-kolor3
                [class*="-control"]
                    border-bottom: 1px solid $kontrast-kolor3
                    color: $kontrast-kolor3
                    background: transparent
                [class*="-singleValue"],
                [class*="-indicatorContainer"]
                    color: $kontrast-kolor1
                [class*="-placeholder"]
                    color: $kontrast-kolor3
                [class*="-menu"]
                    border: 1px solid $kontrast-kolor1
                    color: $kontrast-kolor1
                    background: $kontrast-kolor2
                [class*="-option"]
                    &:hover
                        background: $kontrast-kolor1
                        color: $kontrast-kolor2
                &--richText-editor
                    div
                        background-color: #1D1D1D
            &__import
                color: $kontrast-kolor1

            .btn-dropdown
                color: $kontrast-kolor3

    .text
        &--title,
        &--level-title,
        &--level-desc,
        &--header,
        &--heading
            color: $kontrast-kolor3

    .form-control
        border-bottom: 1px solid $kontrast-kolor1
        color: $kontrast-kolor1
        background: $kontrast-kolor2
        &::placeholder
            color: $kontrast-kolor3

    .block
        &__content, &__header
            color: $kontrast-kolor3

    .show
        .dropdown-new
            .btn-dropdown
                background-color: $kontrast-kolor4
                background: $kontrast-kolor4

                &:hover, &:focus
                    color: $kontrast-kolor1
        .dropdown-menu
            background-color: $kontrast-kolor4
            background: $kontrast-kolor4

    .custom-file-label
        color: $kontrast-kolor1
        background: $kontrast-kolor5
        border-color: $kontrast-kolor1

        &::after
            background: $kontrast-kolor1
            color: $kontrast-kolor5

    .custom-file-input
        &:hover, &:focus
            & + .custom-file-label
                &::after
                    background: $kontrast-kolor5 
                    color: $kontrast-kolor1

    label
        color: $kontrast-kolor3

    main
        background-color: #1D1D1D

    .pagination-top, 
    .pagination-bottom,
    .pagination-bottom__item--disabled
        color: #FFF

    .modal
        background: $kontrast-modal-background
        &-content,
        &-header
            background: $kontrast-kolor4
            color: $kontrast-kolor3
        &-header
            border-bottom: 1px solid $kontrast-kolor3
        &-viewer
            &__select
                option
                    background: $kontrast-kolor4
                    color: $kontrast-kolor1
            .modal-content
                background: $kontrast-kolor4
                border-color: $kontrast-kolor3
            .modal-header
                background: $kontrast-kolor4
                border-bottom: 1px solid $kontrast-kolor3
        &__resources
            &--input
                background: $kontrast-kolor4
                border-color: $kontrast-kolor1
                color: $kontrast-kolor3
            &--list
                border-color: $kontrast-kolor3
                &-item
                    color: $kontrast-kolor3

    .viewer
        background-color: #161615

        &-header
            &__main
                background: $kontrast-kolor2
                color: $kontrast-kolor3
            &__close
                background: $kontrast-kolor1
                border-color: $kontrast-kolor1 !important
                i
                    color: $kontrast-kolor2
                &:hover,
                &:focus
                    background: $kontrast-kolor2
                    i
                        color: $kontrast-kolor1
            &__nav
                border-color: $kontrast-kolor3
                background: $kontrast-kolor2
                color: $kontrast-kolor3

            &__text--title
                &,
                strong
                    color: $kontrast-kolor3
            &__logo--href
                background: $kontrast-kolor1
                img
                    filter: brightness(0)
                &:hover,
                &:focus
                    background: $kontrast-kolor2
                    img
                        filter: sepia() saturate(10000%) hue-rotate(0deg)
            &__dropdown 
                .dropdown-menu
                    background: $kontrast-kolor2
                    border-color: $kontrast-kolor1
                .dropdown-item
                    color: $kontrast-kolor1
                    &:hover,
                    &:focus
                        color: $kontrast-kolor2
                        background: $kontrast-kolor1
        
        &__content
            background: $kontrast-kolor2
            &--header
                background: $kontrast-kolor2
                border-color: $kontrast-kolor1
        
        &__button
            &--title-active
                box-shadow: inset 0px -5px 0px 0px $kontrast-kolor1
            &--fold
                border-color: $kontrast-kolor1
                border-bottom: 1px solid $kontrast-kolor1
                color: $kontrast-kolor1
                span
                    color: $kontrast-kolor1
                &:hover,
                &:focus
                    background: $kontrast-kolor2
                    color: $kontrast-kolor1
        &__aside
            border-color: $kontrast-kolor3
            background: $kontrast-kolor2
            &--container
                border-color: $kontrast-kolor3
                background: $kontrast-kolor2

        .verse__checkbox--accept-double,
        .verse__checkbox--decline
            background: none

    .verse
        &__block--inactive.active 
            .btn-verse-edit
                background: $kontrast-kolor1
                color: $kontrast-kolor2
            .btn-verse
                box-shadow: 0 0 0 3px $kontrast-kolor1
        &__block--inactive
            &:hover,
            &:focus
                .btn-verse
                    box-shadow: 0 0 0 3px $kontrast-kolor1
        &__block--active
            background: $kontrast-kolor2
            box-shadow: 0 0 0 3px $kontrast-kolor1
            color: $kontrast-kolor1
        &__num,
        &__content
            color: $kontrast-kolor3

        &__checkbox
            &--active
                background: $kontrast-kolor2
                border-color: $kontrast-kolor1
            &--accept
                i,
                &-double i
                    color: $kontrast-kolor1
            &--decline i 
                color: $kontrast-kolor1

    .__react_component_tooltip.type-dark
        border: 1px solid $kontrast-kolor3
        &.place-left:after
            border-left-color: $kontrast-kolor3

    .osd-overlay
        border-color: $kontrast-kolor1
        &--highlight
            border-color: $kontrast-kolor1
            .versenumber, .leftbottom, .righttop
                border-color: $kontrast-kolor1
                background-color: $kontrast-kolor1
                span
                    color: $kontrast-kolor6

    .auth
        &-form
            color: $kontrast-kolor3

    .dropdown-menu
        background: $kontrast-kolor2
        border: 1px solid $kontrast-kolor1

    .dropdown-item.disabled
        color: $kontrast-kolor3!important
        background-color: $kontrast-kolor6!important

    .dropdown-item:hover, .dropdown-item:focus
        background: $kontrast-kolor1
        color: $kontrast-kolor6
        

    .dropdown-transcription
        .dropdown-item
            color: $kontrast-kolor3
            &:hover,
            &:focus
                background: $kontrast-kolor1
                color: $kontrast-kolor2

    .dropdown-common
        &__placeholder
            .dropdown-toggle
                color: $kontrast-kolor3

        .dropdown-toggle
            &::after 
                color: $kontrast-kolor3

            &:hover, &:focus
                &::after 
                    color: $kontrast-kolor2

        .dropdown-item
            color: $kontrast-kolor3
            &:hover,
            &:focus
                background: $kontrast-kolor1
                color: $kontrast-kolor2

    .static-page
        background: $kontrast-kolor2
        &__content
            h1, h2, h3, p
                color: $kontrast-kolor3
            a
                color: $kontrast-kolor1
            .nav-tabs
                .nav-link
                    background: $kontrast-kolor1
                    color: $kontrast-kolor2
                    border: 1px solid $kontrast-kolor1
                    &:hover,
                    &:focus
                        background: $kontrast-kolor2
                        color: $kontrast-kolor1
                    &.active
                        background: $kontrast-kolor2
                        color: $kontrast-kolor1

            &--instruction 
                ol 
                    li
                        &::before
                            color: $kontrast-kolor3

            &--read-on-text, &--read-on-description
                color: $kontrast-kolor3

            &--icons-item--text, &--icons-item--text
                color: $kontrast-kolor3

            &--icons-item span
                color: $kontrast-kolor3
                        
    .close
        opacity: 1
        color: $kontrast-kolor1
        text-shadow: none

    input:-internal-autofill-selected 
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px $kontrast-kolor3

    input[type='radio']
        accent-color: $kontrast-kolor1

    