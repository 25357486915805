.remind
    align-items: center
    &__instruction
        font-size: 0.9375rem
        line-height: 1.375rem
        margin-top: 26px
        margin-bottom: 26px
    &__submit
        text-align: right
        margin-top: 55px
        font-size: 1.063rem
        &--link
            margin-right: 43px
        