.change
    align-items: center
    color: #373A3C
    &__instruction
        color: #687078
        font-size: 1rem
        line-height: 1.375rem
        margin-top: 26px
        margin-bottom: 26px
    &__submit
        text-align: right
        margin-top: 55px
        font-size: 1.063rem
        