@import '@Styles/_font-colors.sass'
.DraftEditor-editorContainer
    position: relative
    z-index: 1

div.DraftEditor-root
    width: 100%
    overflow-y: auto
    white-space: pre-wrap
    word-break: break-word
    word-wrap: break-word

    p
        margin: 0

div.DraftEditor-editorContainer,
div.public-DraftEditor-content
    height: 100%

.contrast div.DraftEditor-root
    color: $kontrast-kolor3
    background-color: $kontrast-kolor6
    p
        color: $kontrast-kolor3
        background-color: $kontrast-kolor6

    span
        color: $kontrast-kolor3
        background-color: transparent


.contrast .rdw-editor-toolbar
    color: $kontrast-kolor3
    background-color: $kontrast-kolor6 !important
    border: 0

