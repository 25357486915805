@import "@Styles/_font-colors.sass"
@import "@Styles/_breakpoints.sass"
$logo-mid: url("~@Assets/images/ariadna_logo.2.svg")
$logo-mid-contrast: url("~@Assets/images/ariadna_logo.kontrast2.svg")


.footer
    background: #E2E2E2
    font-size: 1rem
    margin-top: 0
    color: #005CA9

    &__logo
        background-image: $logo-mid
        background-repeat: no-repeat
        width: 187px
        height: 52px
        margin-bottom: 40px
        +kontrast
            background-image: $logo-mid-contrast


    &__inner
        position: relative
        padding-top: 84px
        padding-bottom: 84px
        padding-left: 30px
        padding-right: 30px

    +hd
        font-size: 1rem

    &__block
        +touch
            margin-bottom: 0.5rem

        +widescreen
            padding: 8px 0

        &--header
            color: #161615
            font-weight: bold
            +touch
                margin-bottom: 0.5rem

        &--light-header
            color: #4A4A4A
            margin-top: 1.0rem
            +touch
                margin-bottom: 0.5rem

        & > span
            font-weight: bold
    
    &__build
        position: absolute
        top: 20px
        right: 0
        margin-left: auto
        border: 1px solid red
        border-radius: 30px
        background-color: rgba(255, 0, 0, 0.3)
        color: black
        padding: 10px 20px
        display: flex
        justify-content: space-around
        height: fit-content
        gap: 10px
        +touch
            display: none

        p
            margin-bottom: 0

    &__links
        margin: 0
        padding: 0
        display: flex
        justify-content: space-between
        list-style-type: none

        +touch-sec
            gap: 25px
            flex-direction: column

    &__links-anchor
        &:hover
            text-decoration: none

    &__rights
        color: #4a4a4a
        line-height: 1.25rem
        text-align: center
        padding: 30px 0
        background-color: #f5f5f5

        +kontrast
            background-color: $kontrast-kolor6
        
        +touch-sec
            margin: 10px 0
            border-top: 1px solid #3C3857
            width: 100%
            padding: 25px 0 17px

        &--link
            color: #005CA9
            text-decoration: none

            &:hover
                color: #329AFC

\:global(.contrast)
    .footer
        color: $kontrast-kolor1
        background: $kontrast-kolor5
        &__rights
            color: $kontrast-kolor6
            &--link
                color: $kontrast-kolor1

\:global(.font-medium)
    .footer__block
        &--left
            +hd
                flex: 0 0 45%
                max-width: 45%
            +fullhd
                flex: 0 0 50%
                max-width: 50%
        &--right
            +hd
                flex: 0 0 55%
                max-width: 55%
            +fullhd
                flex: 0 0 50%
                max-width: 50%

\:global(.font-big)
    .footer
        &__block
            &--left
                +hd
                    flex: 0 0 45%
                    max-width: 45%
                +fullhd
                    flex: 0 0 50%
                    max-width: 50%
            &--right
                +hd
                    flex: 0 0 55%
                    max-width: 55%
                +fullhd
                    flex: 0 0 50%
                    max-width: 50% 

        &__links
            @media screen and (max-width: 420px)
                grid-template-columns: none