
$kolor1: #005CA9

$kolor2: #000000

$kolor3: #6F6F6F

$kolor4: #C0C0C0

$kolor5: #FFFFFF

$viewer-kolor1: #008AF9

$viewer-kolor2: #272727

$viewer-kolor3: #363636

$viewer-kolor4: #626262

$kontrast-kolor1: #FFFF00

$kontrast-kolor2: #050505

$kontrast-kolor3: #ffffff

$kontrast-kolor4: #0C0C0C

$kontrast-kolor5: #181818

$kontrast-kolor6: black



$kontrast-modal-background: rgba(0, 0, 0, 0.9)

=kontrast
  \:global(.contrast) &
    @content
  .contrast &
    @content