@import "@Styles/_breakpoints.sass"
@import "@Styles/_mixins.sass"
@import "@Styles/_text.sass"
@import "@Styles/_font-colors.sass"

$mod1Img: url('~@Assets/images/modules/mod-1.svg')
$mod2Img: url('~@Assets/images/modules/mod-2.svg')
$mod3Img: url('~@Assets/images/modules/mod-3.svg')

.modules
    padding-top: 45px
    padding-bottom: 45px
    margin-top: 10px
    background-color: #F5F5F5
    +kontrast
        background-color: #1D1D1D

    +touch
        z-index: 0

    &__slider
        margin-top: 30px

    &__static
        display: block
        +touch
            display: none !important

    &__container 
        padding-top: 45px
        padding-bottom: 45px
    &__slider-container
        display: flex
        justify-content: center
        text-align: center
        margin: auto
        max-width: 350px
    &__tiles
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-gap: 10px
        margin-top: 30px

        +fullhd
            padding-right: 70px

    &__tile 
        position: relative
        height: 430px
        padding-left: 0px
        max-width: 600px
        +touch
            height: 353px
            max-width: 550px
            padding-top: 1px
            margin-top: 50px


        &--content
            background-color: rgba(255,255,255,0.95)
            min-height: 370px
            max-width: 350px
            width: 66%
            display: block
            padding: 20px 20px
            margin-top: 40px
            margin-left: 40px
            +touch
                margin-left: 40px
                width: 75%
            +mobile-small
                margin-left: 0px
                margin-top: 30px
                width: 90%
            z-index: 1
            box-sizing: border-box
            +kontrast
                background-color: $kontrast-kolor6
                color: $kontrast-kolor3

            &:before
                content: ""
                height: 100%
                display: block
                +desktop
                    max-width: 285px
                width: 50%
                position: absolute
                top: 0
                right: 0
                z-index: -1
                background-color: #0b8087
                background-image: $mod1Img
                background-repeat: no-repeat
                background-position: right
                background-size: contain
                +touch
                    width: 100%

            &_1:before
                 background-color: #005CA9
                 background-image: $mod1Img
                 +kontrast
                    opacity: 0.7
                    background-color: #005A8C

            &_2:before
                background-color: #1A5A8C
                background-image: $mod2Img
                +kontrast
                    opacity: 0.7
                    background-color: #005A8C

            &_3:before
                background-color: #161E66
                background-image: $mod3Img
                +kontrast
                    opacity: 0.7
                    background-color: #161E66

    &__hr 
        max-width: 135px
        margin: 20px 0
        border: 0
        border-top: 1px solid #c0c0c0

    &__more
        margin: 35px 0 10px

    &__text
        &--header
            @extend .text__heading--home
            text-align: center
            font-weight: bold
            +kontrast
                color: $kontrast-kolor3

        &--description
            text-align: center
            font-size: 1rem
            color: #4A4A4A
            +kontrast
                color: $kontrast-kolor3

        &--title
            @extend .text--heading-sub
            font-weight: 500
            min-height: 90px
            margin: 0

        &--content 
            height: 130px
            @extend .text--body

        &--more
            @extend .text__link

    &__carousel
        max-width: 350px


    &__slide
        +desktop
            background: rgba(255,255,255,0.95)
        max-width: 350px
        width: 100%
        padding: 35px 45px
        margin: 40px 0
        position: relative
        z-index: 1
        box-sizing: border-box
        box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 0.3)
        &_1
            background: #0b8087 $mod1Img no-repeat
            background-size: contain
        &_2
            background: #405477 $mod2Img no-repeat
            background-size: cover
        &_3
            background-size: contain
            background: #8e4885 $mod3Img no-repeat right