@import "@Styles/_breakpoints.sass"
@import "@Styles/_mixins.sass"

.viewer
    color: #B0B0B0
    background-color: $viewer-kolor3
    display: flex
    flex: 1 0
    justify-content: space-between
    position: relative
    

    &-btn__wrapper
        height: 100%

    &:not(.viewer--toggle)
        .viewer__panel--osd
            &:not(.viewer__panel--fold)
                .viewer__icon--fold
                    @extend .icon-arrow-double-left-full

            &.viewer__panel--fold
                .viewer__icon--fold
                    @extend .icon-arrow-double-right-full

        .viewer__panel--editor
            &:not(.viewer__panel--fold)
                .viewer__icon--fold
                    @extend .icon-arrow-double-right-full

            &.viewer__panel--fold
                .viewer__icon--fold
                    @extend .icon-arrow-double-left-full
                    
    &.viewer--toggle
        .viewer__panel--osd
            &:not(.viewer__panel--fold)
                .viewer__icon--fold
                    @extend .icon-arrow-double-right-full

            &.viewer__panel--fold
                .viewer__icon--fold
                    @extend .icon-arrow-double-left-full

        .viewer__panel--editor
            &:not(.viewer__panel--fold)
                .viewer__icon--fold
                    @extend .icon-arrow-double-left-full

            &.viewer__panel--fold
                .viewer__icon--fold
                    @extend .icon-arrow-double-right-full

    &--toggle
        +widescreen
            .viewer__panel--osd
                order: 2
            .viewer__divider
                order: 1

    &__alert
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1030
        background: rgba(0,0,0,0.3)
        
        &--box
            border: 1px solid black
            border-radius: 5px
            padding: 8px 15px
            color: #1A73E8
            background-color: #F2F9FC
            border-color: #CDE1F8
            margin: 4px 30px
            box-shadow: 0 0 1em rgba(0,0,0,0.3)d


    &__icon
        &--fold
            @extend .icon

    &__panel
        flex: 1
        display: flex

 

        +touch-sec
            display: none

            &--mobile
                display: flex

        +widescreen
            &--fold
                flex: 0 0 55px
                
                .viewer__content, .viewer__menu
                    display: none

    &__ocd
        width: 100%
        height: 100%

    &__content
        flex: 1
        background: $viewer-kolor2
        display: flex
        flex-direction: column
        margin-bottom: 5px

        &--header
            border-bottom: solid 1px $viewer-kolor4 !important
            background: $viewer-kolor3
            border-bottom: 2px solid #000
            display: flex
            position: relative

            +touch-sec
                border-bottom: 1px solid #278BED

            &-edit
                position: absolute
                right: 5px
                top: 0px

                +touch
                    display: none

        &--inner
            flex: 1

    &__aside
        flex: 0 0 50px
        border-left: solid 1px $viewer-kolor4
        border-right: solid 1px $viewer-kolor4
        margin-bottom: 5px

        +touch-sec
            display: none
        
        &--container
            width: 100%
            height: 100%
            background: $viewer-kolor3

        &--menu
            border-top: 1px solid $viewer-kolor4

    &__menu
        max-height: calc(100vh - 201px)
        overflow-y: auto
        overflow-x: visible

        &--list 
            padding: 10px 0
            margin: 0
            list-style: none
            display: grid
            grid-gap: 4px
            align-items: center
            justify-content: center

        &--icon
            font-size: 1.25rem

        .dropright .dropdown-menu
            margin-left: 10px

        .dropdown-menu
            background: #4C4C4C
            padding: 8px 0
            position: relative
            border-radius: 0
            position: fixed !important

            &::after, &::before
                display: block
                position: absolute
                content: ""
                width: 0
                height: 0
                border-top: 5px solid transparent
                border-bottom: 5px solid transparent

            &::before
                left: -5px
                top: 11px
                background: #4C4C4C

            &::after
                left: -4px
                top: 12px
                border-right: 5px solid #4C4C4C

            .btn-viewer-button
                margin: 0

        .dropdown-item
            color: #FFFFFF
            padding: 0 10px
            font-size: 0.75rem
            display: flex
            align-items: center

            &.disabled
                opacity: 0.5

            span
                border: solid 1px #5D5D5D
                display: inline-block
                width: 34px
                height: 34px
                border-radius: 4px
                margin: 3px 10px 3px 0

                i
                    font-size: 1.35rem

                img
                    font-size: 1.35rem
                    padding: 8px

            &:hover, &:focus
                color: #FFFFFF
                background: #1E2026

                span
                    background: #1E2026

        &::-webkit-scrollbar
            width: 6px
            
        &::-webkit-scrollbar-thumb 
            background: #8e8e8e
            border-radius: 10px

    &__charmap
        list-style-type: none
        margin: 0
        padding: 0 8px
        display: grid
        grid-template-columns: repeat(6, 25px)
        grid-gap: 3px
        max-height: 140px
        overflow-y: auto
            
    &__divider
        background: #171717
        border-right: 1px solid #404453
        border-left: 1px solid #404453
        flex: 0 0 8px
        +touch-sec
            display: none

    &__verses
        max-height: calc(100vh - 201px)
        overflow-y: auto

        &::-webkit-scrollbar
            width: 6px
            
        &::-webkit-scrollbar-thumb 
            background: #8e8e8e
            border-radius: 10px

        &--all-verses-status
            width: 100%
            height: 30px
            border-bottom: solid 1px $viewer-kolor4
            background: $viewer-kolor3
            padding: 6px 0px
            display: flex
            align-items: center
            font-size: 0.9rem

            .verse__checkbox
                margin-left: 15px
                margin-right: 5px
                background-color: $viewer-kolor2

                &--decline, &--accept-double
                    visibility: hidden

                &:hover
                    .verse__checkbox--decline, .verse__checkbox--accept-double
                        visibility: visible
                        
                    
                &:focus
                    color: #ffffff   

        &--list
            list-style-type: none
            margin: 0
            padding: 0px            

        &--item
            display: flex         
            &:first-child:not(:last-child)
                .verse__status
                    .verse__tooltip--status
                        top: 0
            &:last-child:not(:first-child)
                .verse__status
                    .verse__tooltip--status
                        bottom: 0

        &--ghostbutton
            width: 100%
            height: 100%
            position: absolute
            background: transparent


    &__divider
        height: 1px
        margin: 5px auto
        background: transparent
        border-color: transparent
        width: 34px

    &__button
        &--menu
            border-radius: 0 !important
            width: 34px
            height: 34px !important
            padding: 0 !important
            line-height: 1 !important

            i
                font-size: 1.35rem

            .icon-instapaper
                font-size: 0.9rem

        &--fold
            width: 100%
            background: #1e2026
            border-bottom: 1px solid #626262 !important
            padding-bottom: 1px !important
            height: 36px

            span
                text-decoration: underline

        &--title
            +touch-sec
                color: white !important
                background: #25272E !important
                +box-shadow(inset 0px -2px 0px 0px #008AF9)
        
            +widescreen
                pointer-events: none

            &-active
                &, &:hover, &:focus
                    background: #1E2026 !important
                    +box-shadow(inset 0px 40px 0px 0px #008AF9)
            
            &-active.viewer__button--title-osd
                +widescreen
                    +box-shadow(none)
            

            &-osd
                &:nth-child(2), &:nth-child(3)
                    +widescreen
                        display: none
            &-editor
                &:nth-child(1)
                    +widescreen
                        display: none
            &-layer
                pointer-events: auto
                margin-left: auto
                cursor: pointer
                position: relative

            &[disabled] 
                pointer-events: none !important

        &--student-mode 
            &:not(.viewer__button--title-active)
                pointer-events: auto

        &--active:enabled
            border-color: white !important

.__react_component_tooltip.type-dark
        border: none
        background: #959595
        font-size: 0.75rem
        padding: 3px 10px
        color: #212121
        border-radius: 2px
        &:after, &:before
            display: none

.openseadragon-canvas:focus
    outline: none
    