@import "@Styles/_breakpoints.sass"

.lang-dropdown

    &__button
        background-color: #FFFFFF
        font-size: 1rem
        height: auto
        width: auto
        border: none
        border-radius: 0px
        padding: 2px 2px
        margin: 0px 10px
        line-height: 20px
        color: #005CA9
        border-bottom: 1.5px solid transparent
        &::after
            margin-left: 14px

        &:hover,
        &:focus
            border-color: #1C4191
            background-color: #FFFFFF
            color: #005CA9
            border-bottom: 1.5px solid #005CA9

        &[aria-expanded="true"]
            color: #005CA9
            background-color: #FFFFFF
            border-color: #1C4191
            &::after
                transform: rotate(180deg)
        
        +touch
            display: flex
            justify-content: space-between
            align-items: center
            width: 85px
            height: 38px
            font-size: 1rem
            background-color: #F8F8F8
            border-color: #D3D3D3
            color: #1A73E8
            &::after
                color: #404244

            &:hover
                background-color: #F8F8F8
                border-color: #D3D3D3
                color: #373A3C
                font-size: 1rem

            &[aria-expanded="true"]
                background-color: #F8F8F8
                border-color: #D3D3D3

        &:focus
            outline: none
        

    &__menu
        margin-top: 5px
        left: auto
        right: 0
        min-width: 90px
        font-size: 1rem
        border: 1px solid #DDDDDD
        padding: 5px 0px
        z-index: 1022

        +touch
            background-color: #FFF
            border-color: #DDD

            &::before
                border-bottom-color: #DDD

            &::after
                border-bottom-color: #FFF

    &__item
        color: #373A3C !important
        font-size: 0.875rem
        height: 38px
        padding-left: 35px
        display: flex
        align-items: center
        background-color: #DFEAF4

        +touch
            color: #383B3C

        &:hover
            color: #FFF
            background-color: #1A73E8
            +touch
                background-color: #fff
                color: #1A73E8

        &:active
            background-color: #F4F4F4
            color: #373A3C
        
        &--not-active
            color: #005CA9 !important
            background-color: #FFFFFF
        
        i
            position: absolute
            left: 13px

\:global(.contrast)
    .lang-dropdown
        &__button
            background: #0C0C0C
            color: #FFFF00
            &::after
                color: #FFFF00
            &:hover,
            &:focus
                border-color: #FFFF00

        &__menu
            border-color: #FFFF00
            &::before,
            &::after
                border-bottom-color: #FFFF00
            
        &__item
            background: #0C0C0C
            border-color: #FFFF00
            color: #FFFF00 !important
            &:hover,
            &:focus
                background: #FFFF00
                color: #0C0C0C !important

\:global(.font-medium)
    .lang-dropdown
        &__menu
            z-index: 1022

\:global(.font-big)
    .lang-dropdown
        &__menu
            z-index: 1022