
  
  @font-face
    font-family: 'fontello'
    src: url(~@Assets/icons/fontello.eot?75004660)
    src: url(~@Assets/icons/fontello.eot?75004660#iefix) format('embedded-opentype'), url('~@Assets/icons/fontello.woff2?75004660') format('woff2'), url('~@Assets/icons/fontello.woff?75004660') format('woff'), url('~@Assets/icons/fontello.ttf?75004660') format('truetype'), url('~@Assets/icons/fontello.svg?75004660#fontello') format('svg')
    font-weight: normal
    font-style: normal


[class^="icon-"]:before,
[class*=" icon-"]:before,
.icon:before
    font-family: "fontello"
    font-style: normal
    font-weight: normal
    speak: none
    display: inline-block
    text-decoration: inherit
    width: 1em
    margin-right: .2em
    text-align: center
    font-variant: normal
    text-transform: none
    line-height: 1em
    margin-left: .2em
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.icon-question:before 
  content: '\e800'



.icon-instapaper-1:before 
  content: '\e801'


.icon-instapaper:before 
  content: '\e802'

.icon-sort:before 
  content: '\f0dc'

.icon-sort-down:before 
  content: '\f0dd'


.icon-sort-up:before 
  content: '\f0de'


/* '' */

.icon-header-1-end:before 
  content: '\e803'


/* '' */

.icon-header-2-end:before 
  content: '\e804'


/* '' */

.icon-header-3-end:before 
  content: '\e805'


/* '' */

.icon-list-item-end:before 
  content: '\e806'


/* '' */

.icon-list-end:before 
  content: '\e807'


/* '' */

.icon-accept:before 
  content: '\e808'


/* '' */

.icon-table-end:before 
  content: '\e809'


/* '' */

.icon-add:before 
  content: '\e80a'


/* '' */

.icon-accept-double:before 
  content: '\e80b'


/* '' */

.icon-add-area:before 
  content: '\e80c'


/* '' */

.icon-add-circle:before 
  content: '\e80d'


/* '' */

.icon-add-reversed:before 
  content: '\e80e'


/* '' */

.icon-arrow-double-left-full:before 
  content: '\e80f'


/* '' */

.icon-arrow-double-right-full:before 
  content: '\e810'


/* '' */

.icon-arrow-down-full:before 
  content: '\e811'


/* '' */

.icon-arrow-left-full:before 
  content: '\e812'


/* '' */

.icon-arrow-right-full:before 
  content: '\e813'


/* '' */

.icon-arrow-up-full:before 
  content: '\e814'


/* '' */

.icon-bold:before 
  content: '\e815'


/* '' */

.icon-books:before 
  content: '\e816'


/* '' */

.icon-box:before 
  content: '\e817'


/* '' */

.icon-chevron-double-left:before 
  content: '\e818'


/* '' */

.icon-chevron-double-right:before 
  content: '\e819'


/* '' */

.icon-chevron-down:before 
  content: '\e81a'


/* '' */

.icon-chevron-left:before 
  content: '\e81b'


/* '' */

.icon-verses-desc:before 
  content: '\e81c'


/* '' */

.icon-fit-to-window:before 
  content: '\e81d'


/* '' */

.icon-fix-to-screen:before 
  content: '\e81e'


/* '' */

.icon-user:before 
  content: '\e81f'


/* '' */

.icon-chevron-right:before 
  content: '\e820'


/* '' */

.icon-chevron-up:before 
  content: '\e821'

/* '' */

.icon-close:before 
  content: '\e822'


/* '' */

.icon-close-circle:before 
  content: '\e823'


/* '' */

.icon-comment:before 
  content: '\e824'


/* '' */

.icon-compare:before 
  content: '\e825'


/* '' */

.icon-cookies:before 
  content: '\e826'


/* '' */

.icon-dash-short:before 
  content: '\e827'


/* '' */

.icon-dash-wide:before 
  content: '\e828'


/* '' */

.icon-dot:before 
  content: '\e829'


/* '' */

.icon-music:before 
  content: '\e82a'


/* '' */

.icon-link-ext:before 
  content: '\e82c'


/* '' */

.icon-info:before 
  content: '\e82d'


/* '' */

.icon-index-person:before 
  content: '\e82e'


/* '' */

.icon-index-place:before 
  content: '\e82f'


/* '' */

.icon-indent-right:before 
  content: '\e82b'


/* '' */

.icon-download:before 
  content: '\e830'


/* '' */

.icon-edit:before 
  content: '\e831'


/* '' */

.icon-external:before 
  content: '\e832'


/* '' */

.icon-eye:before 
  content: '\e833'


/* '' */

.icon-eye-disabled:before 
  content: '\e834'


/* '' */

.icon-files2:before 
  content: '\e835'


/* '' */

.icon-forward:before 
  content: '\e836'


/* '' */

.icon-full-screen:before 
  content: '\e837'


/* '' */

.icon-full-screen-close:before 
  content: '\e838'


/* '' */

.icon-hamburger:before 
  content: '\e839'


/* '' */

.icon-index-term:before 
  content: '\e83a'


/* '' */

.icon-header:before 
  content: '\e840'


/* '' */

.icon-header2:before 
  content: '\e841'


/* '' */

.icon-header3:before 
  content: '\e842'


/* '' */

.icon-heart:before 
  content: '\e843'


/* '' */

.icon-home:before 
  content: '\e844'


/* '' */

.icon-image-1:before 
  content: '\e845'


/* '' */

.icon-italic:before 
  content: '\e846'


/* '' */

.icon-link:before 
  content: '\e847'


/* '' */

.icon-link2:before 
  content: '\e848'


/* '' */

.icon-list:before 
  content: '\e849'


/* '' */

.icon-list-end-1:before 
  content: '\e850'


/* '' */

.icon-locked:before 
  content: '\e851'


/* '' */

.icon-mail:before 
  content: '\e852'


/* '' */

.icon-more:before 
  content: '\e853'


/* '' */

.icon-page-description:before 
  content: '\e854'


/* '' */

.icon-page-number:before 
  content: '\e855'


/* '' */

.icon-question-reversed:before 
  content: '\e856'


/* '' */

.icon-quote:before 
  content: '\e857'


/* '' */

.icon-reload:before 
  content: '\e858'


/* '' */

.icon-remove-reversed:before 
  content: '\e859'


/* '' */

.icon-search:before 
  content: '\e860'


/* '' */

.icon-settings:before 
  content: '\e861'


/* '' */

.icon-share:before 
  content: '\e862'


/* '' */

.icon-symbol:before 
  content: '\e863'


/* '' */

.icon-to-end:before 
  content: '\e864'


/* '' */

.icon-to-start:before 
  content: '\e865'


/* '' */

.icon-transcription:before 
  content: '\e866'


/* '' */

.icon-unlocked:before 
  content: '\e867'


/* '' */

.icon-verse-auto-transcription:before 
  content: '\e868'


/* '' */

.icon-verse-clear:before 
  content: '\e869'


/* '' */

.icon-verse-comment:before 
  content: '\e870'


/* '' */

.icon-verse-delete:before 
  content: '\e871'


/* '' */

.icon-verse-down:before 
  content: '\e872'


/* '' */

.icon-verse-footer:before 
  content: '\e873'


/* '' */

.icon-verse-header:before 
  content: '\e874'


/* '' */

.icon-verse-move:before 
  content: '\e875'


/* '' */

.icon-verse-up:before 
  content: '\e876'


/* '' */

.icon-zoom-in:before 
  content: '\e877'


/* '' */

.icon-zoom-out:before 
  content: '\e878'


/* '' */

.icon-text-italic:before 
  content: '\e879'


/* '' */

.icon-remove-area:before 
  content: '\e87a'


/* '' */

.icon-change-site:before 
  content: '\e880'


/* '' */

.icon-image:before 
  content: '\e881'


/* '' */

.icon-image-description:before 
  content: '\e882'


/* '' */

.icon-metadata:before 
  content: '\e883'


/* '' */

.icon-site-transcription:before 
  content: '\e884'


/* '' */

.icon-table:before 
  content: '\e885'


/* '' */

.icon-table-cell-end:before 
  content: '\e886'


/* '' */

.icon-table-verse-end:before 
  content: '\e887'


/* '' */

.icon-circle-warning-empty:before 
  content: '\e888'


/* '' */

.icon-lock-close2:before 
  content: '\e889'


/* '' */

.icon-lock-open2:before 
  content: '\e890'


/* '' */

.icon-accept2:before 
  content: '\e891'


/* '' */

.icon-plus3:before 
  content: '\e892'


/* '' */

.icon-plus2:before 
  content: '\e893'


/* '' */

.icon-strike:before 
  content: '\f0cc'


/* '' */

.icon-underline:before 
  content: '\f0cd'


/* '' */

.icon-subscript:before 
  content: '\f12c'


/* '' */

.icon-map-o:before 
  content: '\f278'


/* '' */

.icon-wpforms:before 
  content: '\f298'


/* '' */

.icon-slash:before 
  content: '/'
