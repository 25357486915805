@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"

$logo-big: url("~@Assets/images/ariadna_logo.svg")
$logo-big-hover: url("~@Assets/images/ariadna_logo.svg")
$logo-big-active: url("~@Assets/images/ariadna_logo.svg")
$logo-big-contrast: url("~@Assets/images/ariadna_logo.kontrast.svg")

$logo-long: url("~@Assets/images/ariadna_logo.svg")
$logo-long-active: url("~@Assets/images/ariadna_logo.svg")
$logo-long-contrast: url("~@Assets/images/ariadna_logo.kontrast.svg")


$eu-logo-mobile: url("~@Assets/images/UE_mobile.svg")
$eu-logo-mobile-kontrast: url("~@Assets/images/UE_mobile.kontrast.svg")

.test
    height: 123px
    position: relative

.header
    position: fixed
    z-index: 1020
    width: 100%
    height: 123px
    background-color: #ffffff

    &__inner 
        display: flex
        height: 100%
        justify-content: space-between
        align-items: center
        background-color: #ffffff

    &__ue-logo
        height: 53px
        width: 82px
        background-image: $eu-logo-mobile
        background-size: 82px 53px
        background-repeat: no-repeat
        
        +kontrast
            background-image: $eu-logo-mobile-kontrast

    +touch
        background-color: #FFFFFF
    &__subpage
        height: 130px
        position: relative
        +touch
            background: #FFFFFF
        &::after
            +desktop
                content: ''
                position: absolute
                width: 100%
                height: 100%
                left: 0
                top: 0
                //background: transparent linear-gradient(90deg, rgba(14, 20, 46, 0.5) 0%, rgba(26, 47, 68, 0.5) 33%, rgba(43, 63, 99, 0.5) 54%, rgba(14, 20, 46, 0.5) 100%)

    &__collapsed
        +touch
            background: #FFF
            max-width: 100%
            height: calc(100vh - 70px)
        
        .header__logo--big
            +touch
                display: none
        .header__column
            padding: 0
            +touch
                flex-direction: column
    &__wrapper
        padding: 50px 0 60px
        +touch
            padding: 0
            overflow: auto
        &--sticky
            +desktop
                background-color: #fff
                border-bottom: 1px solid #E5E5E5
    &__row
        &--sticky
            justify-content: space-between
            +desktop
                display: flex
                align-items: center

    &__column
        display: flex
        justify-content: space-between
        +desktop
            align-items: center

    &__container
        margin-top: 85px
        background: transparent
        justify-content: space-between
        &--sticky
            +touch
                margin-top: 18px
                margin-bottom: 18px
                justify-content: space-between

    &__logo
        height: 100px
        width: 257px
        float: left
        background-image: $logo-long
        background-size: cover
        text-indent: -999999rem
        &:hover,
        &:focus,
        &:active
            background-image: $logo-long-active

        &--big
            height: 82px
            width: 217px
            float: left
            background-image: $logo-big
            background-size: cover
            text-indent: -999999rem
            &:hover,
            &:focus
                background-image: $logo-big-hover
            &:active
                background-image: $logo-big-active
            +touch
                height: 70px
                width: 187px
                margin-bottom: 18px
                margin-top: 18px

\:global(.contrast)
    .header
        background: $kontrast-kolor4
        +touch
            background: $kontrast-kolor4
            border-bottom: 1px solid $kontrast-kolor3
        &__collapsed
            background: $kontrast-kolor4

        &__wrapper--sticky
            background: $kontrast-kolor4

            
        &__inner 
            background-color: $kontrast-kolor4

        &__logo
            height: 100px
            background-image: $logo-long-contrast
            &:hover,
            &:focus,
            &:active
                background-image: $logo-long-contrast

            &--big
                &:hover,
                &:focus,
                &:active
                    background-image: $logo-big-contrast
        &__subpage
            +touch
                background: #0C0C0C
                border-bottom: 1px solid $kontrast-kolor3
