@import "@Styles/_breakpoints.sass"

.show
    .dropdown-new
        .btn-dropdown
            background-color: #fff
            background: #fff
            border-color: #6F6F6F


.dropdown
    &-menu
        border-radius: 0px

    &-common
        &__placeholder
            .dropdown-toggle
                color: #6c757d
                font-style: italic

        .dropdown-toggle
            background: #FFF
            border-color: #ced4da
            font-size: inherit
            width: 100%
            padding: 6px 12px
            display: flex
            justify-content: space-between
            align-items: center
            
            
            &::after
                color: #373A3C
                border-top: 0.2em solid
                border-right: 0.2em solid transparent
                border-left: 0.2em solid transparent    

        .dropdown-menu[x-placement="bottom-start"]
            &::before,
            &::after
                content: ''
                display: block
                position: absolute
                bottom: 100%
                width: 0
                height: 0

            &::before
                left: 22px
                border: 5px solid transparent
                border-bottom-color: #DDDDDD

            &::after
                left: 23px
                border: 4px solid transparent
                border-bottom-color: #FFF

        .dropdown-item
            width: 100%
            margin-top: 8px
            padding: 3px 35px
            font-size: 0.9375rem
            &:hover,
            &:focus,
            &:active
                background: #DFEAF4

            i
                position: absolute
                left: 5px

            +mobile
                width: 100%
                margin-left: 0
    &-new
        & .dropdown-toggle 
            box-shadow: none
            background: none
            border: none
            border-radius: 0
            padding: 2px 2px 0px 2px
            border-bottom: 2px solid #6F6F6F
            &::after
                border-top: 0.3em solid
                border-right: 0.3em solid transparent
                border-left: 0.3em solid transparent
                margin-left: 10px 
                color: #005CA9
            &[aria-expanded="true"]
                &::after
                    transform: rotate(180deg)
        & .btn-dropdown
            &:hover
                border-bottom: 2px solid #005CA9
        & .show
            background-color: #fff
            background: #fff
            color: red
        & .dropdown-menu
            border-radius: 0
            max-height: 200px
            overflow-y: auto




    &-transcription
        .dropdown-toggle
            display: flex
            justify-content: space-between
            align-items: center
            border-radius: 4px
            font-size: 0.875rem
            padding: 6px 10px 7px
            width: 100%
            text-align: left

        .dropdown-menu[x-placement="bottom-start"]
            &::before,
            &::after
                content: ''
                display: block
                position: absolute
                bottom: 100%
                width: 0
                height: 0

            &::before
                right: 22px
                border: 5px solid transparent
                border-bottom-color: #DDDDDD

            &::after
                right: 23px
                border: 4px solid transparent
                border-bottom-color: #FFF

        .dropdown-item
            color: #217ACC
            padding: 5px 10px
            white-space: normal
            font-size: 0.875rem
            &:hover,
            &:focus
                color: #217ACC
                background: #F2F2F2
            &:active
                background: #F2F2F2
                color: #373A3C

.show
    .dropdown-viewer
        .btn-dropdown
            background-color: transparent
            background: transparent
            color: white
            padding: 5px

            &:hover
                background: #404040


.dropdown-new
    .dropdown-menu
        &::after, &::before
            display: none

    &.dropdown-viewer
        .dropdown-menu
            background: #404040

        .dropdown-item
            color: white

            &:hover,
            &:focus
                background: #161615

            &__active i
                color: #217ACC