@import "@Styles/_breakpoints.sass"
    
.btn
    &:hover, &:focus
        box-shadow: none

    &-override
        vertical-align: inherit
        border: 0
        line-height: inherit
        border-radius: 0
        font-weight: inherit
        text-align: inherit
        //border: 2px solid #005CA9

    &-primary
        border: 0
        box-shadow: 0 0 0 2px #005ca9
        background: #005CA9
        color: #ffffff
        border-radius: 0

        &.viewer-header__menu-button
            background-color: #008AF9
            box-shadow: 0 0 0 2px #008AF9
            border-color: #008AF9

        &.verse
            box-shadow: 0 0 0 2px #008AF9
            &:hover, &:focus
                box-shadow: 0 0 0 2px #008AF9


        &:hover, &:focus
            background: #ffffff
            color: #005CA9
            box-shadow: 0 0 0 2px #005CA9
            border-color: #005CA9

        &.disabled,
        &:disabled
            pointer-events: none
            background: #d9d9d9
            color: #6b7073
            border-color: #bdbdbd
            box-shadow: 0 0 0 2px #bdbdbd

        &:not(:disabled):not(.disabled):active
            background: #F4F4F4
            color: #373A3C

    &-secondary
        background-color: #FFFFFF
        box-shadow: 0 0 0 2px #005CA9
        border-radius: 0
        border: none
        //padding: 10px 40px
        color: #005CA9

        &:hover, &:focus
            background-color: #005CA9
            box-shadow: 0 0 0 2px #005CA9
            color: #FFFFFF
            text-decoration: none

        &:not(:disabled):not(.disabled):active
            background-color: #0E60CB
            color: #fff

        &[disabled],
        &:disabled,
        &.disabled
            color: #919598
            box-shadow: 0 0 0 2px #D8D8D8
            background: #EFEFEF
            pointer-events: none

    &-third
        background: #C9C9C9
        border-color: #C9C9C9
        color: #373A3C

        &:hover, &:focus
            background: #b7b7b7
            border-color: #b7b7b7
            color: #373A3C
    &-editQuota
        @extend .btn-secondary
        width: 28px
        height: 28px
        border-radius: 0px
        &:hover, &:focus
            border-color: #0E60CB

    &-quotaAccept
        @extend .btn-primary
        width: 28px
        height: 28px
        border-radius: 0px

    &-cancel
        background: #e6e6e6
        border-color: #C9C9C9
        color: #373A3C
        padding: 0 6px
        font-size: 1.4rem

    &-primary, &-secondary
        &.icon
            padding: 0
            width: 55px
            i
                font-size: 1.3rem

            &-xl
                i 
                    font-size: 1.5rem
    &-editQuota, &-quotaAccept
        &.icon
            padding: 0
            width: 28px
            margin-right: 10px
            i
                font-size: 1.3rem
                display: flex
                align-items: center
                justify-content: center

    &-primary, &-third
        &.verse
            margin: 0
            padding: 0
            border-radius: 0
            height: 100%
            border: 0


    &-primary.verse
        width: 40px

    &-third.verse
        width: 36px


    &-external
        display: block
        color: #373A3C
        background-color: #E6E6E6
        border-color: #D5D5D5
        width: 100%
        padding: 11px

        &:hover,
        &:focus
            color: #FFF
            background-color: #1A73E8
            border-color: #D5D5D5

        &:active
            color: #FFF
            background-color: #0E60CB
            border-color: #D5D5D5

        i
            font-size: 0.8rem
            line-height: 0.7rem
            vertical-align: text-top

    &-submit
        @extend .btn-primary
        padding: 10px
        min-width: 215px
        font-size: 1.062rem

    &-verseinfo
        padding: 0
        margin: 0
        color: white
        width: 26px
        border-color: transparent

        &:hover, &:focus
            color: white
            background: #424242
            border-radius: 2px

        &::after
            display: none

        &[disabled]
            opacity: 1
            pointer-events: none

    &-verse
        padding: 0
        margin: 0
        width: 100%
        display: flex
        border-radius: 0px
        align-items: center

        &:hover, &:focus
            box-shadow: 0 0 0 3px #1C7CD5

        &.active
            & + button
                display: flex

    &-verse-edit
        padding: 0
        margin: 0
        display: none
        background: #0E60CB
        color: white
        border-radius: 0
        position: absolute
        right: 0
        top: 0

        border: 0
        width: 40px
        height: 100%
        
        align-items: center
        justify-content: center

        i
            font-size: 1.2rem

        &:hover
            color: white
            background: #1C7CD5

    &-verse-content
        padding: 0
        margin: 0
        display: flex
        align-items: center
        
        width: 100%
        height: auto
        line-height: inherit
        color: white
        font-size: 0.875rem
        border: 0
        position: relative

        &:hover, &:focus
            color: white

    &-link
        @extend .btn-override

        background-color: transparent
        color: #373A3C
        padding: 0
        font-size: inherit
        text-decoration: underline
        &:hover
            text-decoration: underline

    &-link-external
        @extend .btn-link

        i
            font-size: 0.65rem
            line-height: 0.7rem
            vertical-align: text-top

    &-link-onblack
        @extend .btn-link

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-link-external-onblack
        @extend .btn-link-external

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-icon
        @extend .btn-override
        padding: 0

    &-viewer-full
        @extend .btn-override
        color: #DDDDDD
        font-size: 0.75rem
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        line-height: 1.0
        padding: 2px

        &:hover, &:focus, &:active
            color: #FFFFFF

        &:hover, &:focus
            background: #1A73E8

        &:active
            background: #0E60CB

    &-viewer-title
        @extend .btn-override
        padding: 0 15px
        height: 35px
        display: flex
        justify-content: center
        align-items: center
        background: #1E2026
        color: #C0C0C0
        font-size: 0.6875rem
        flex: 1
        +widescreen
            font-size: 1rem
            flex: 0 0 285px
            background-color: transparent !important
            justify-content: flex-start
            padding-left: 15px

        &:hover, &:focus, &:active
            color: #FFFFFF

        &:hover, &:focus
            background: #1A73E8

        &:active
            background: #0E60CB

    &-viewer-button
        @extend .btn-override
        height: 100%
        border-radius: 4px
        background-color: #4d4d4d
        border: solid 1px #595959
        color: #DDDDDD
        overflow: hidden
        padding: 0 10px
        display: flex
        justify-content: center
        align-items: center
        font-size: 0.875rem
        margin: 0 3px
        position: relative
        outline: 0

        &--active
            @extend .btn-viewer-button
            background: #1E2026
            
            &:hover
                background: #1E2026
                color: #DDDDDD
                text-decoration: none
                box-shadow: none

        &:hover
            background: #1E2026
            color: #DDDDDD
            text-decoration: none

        &:focus, &:hover
            box-shadow: none
            outline: 0
            

        &[disabled]
            color: #959595
            pointer-events: none

        +touch-sec
            font-size: 0.6875rem

        &::after
            position: absolute
            right: 3px
            bottom: 1px
            transform: rotate(45deg)
            opacity: 0.5

    &-viewer-editSelection
        font-size: 0.75rem
        background: #1A73E8
        color: #ffffff
        padding: 0 5px 3px 5px

        i 
            font-size: 1.2rem
            position: relative
            top: 2px


        &:hover, &:focus
            background: #0E60CB
            color: #ffffff

        &:disabled
            pointer-events: none
            background: #535353
            color: #ffffff
            border-color: #535353
            
            
    &-dropdown
        background: #FFF
        border-color: #DDDDDD
        color: #373A3C
        &:hover,
        &:focus,
        &:active
            background: #F4F4F4
            border-color: #DDDDDD
            color: #373A3C
    
    &-dropdown-secondary
        @extend .btn-dropdown
        width: 100%
        border-top: 4px solid #1C7CD5
        display: flex
        justify-content: space-between
        margin-top: 28px
        &::after
            display: none

    &-download
        &:hover,
        &:focus
            background: #1A73E8
            color: #FFF
        &:active
            background: #0E60CB
            color: #FFF

.show
    .btn-dropdown
        background: #F4F4F4
        border-color: #DDDDDD
        color: #373A3C

    .btn-viewer-button
        background: #1E2026
        color: #DDDDDD
        text-decoration: none
        box-shadow: none
        

.contrast
    .btn
        &-primary
            background: #FFFF00
            border-color: #FFFF00
            color: #181818
            box-shadow: 0 0 0 2px #181818

            &:hover, 
            &:focus, 
            &:active,
            &:not(:disabled):not(.disabled):active
                color: #FFFF00
                background: #181818
                border-color: #FFFF00

        &-secondary
            color: #FFFF00
            background: #181818
            border-color: #FFFF00
            &:hover, 
            &:focus, 
            &:active,
            &:not(:disabled):not(.disabled):active
                background: #FFFF00
                border-color: #FFFF00
                color: #181818
                i
                    color: #181818

        &-viewer-button,
        &-third,
        &-verseinfo,
        &-external,
        &-dropdown-secondary,
        &-dropdown
            color: #FFFF00
            background: #181818
            border-color: #FFFF00
            &:hover, 
            &:focus, 
            &:active
                background: #FFFF00
                border-color: #FFFF00
                color: #181818
                i
                    color: #181818
        &-link,
        &-link-onblack,
        &-viewer-title
            color: #FFFF00

        &-verse
            &:hover,
            &:focus
                box-shadow: 0 0 0 3px #FFFF00
            &-edit
                background: #FFFF00
                color: #181818

        &-viewer-button
            &:hover,
            &:focus
                background: #FFFF00 !important

        &-viewer-title
            background: #0c0c0c

        

            
