@import "@Styles/_breakpoints.sass"
@import "@Styles/_mixins.sass"
    
.viewer-header
    &__main
        background: $viewer-kolor2
        color: #DDDDDD
        display: flex
        align-items: center
        height: 50px

    &__title
        flex: 1
        padding: 0 20px
        min-width: 0

        +touch-sec
            padding: 0 13px

    &__prev-page, &__next-page
        margin: 10px

        span
            color: #D0D0D0
            cursor: pointer
            text-decoration: underline
            white-space: nowrap

    &__page-number
        white-space: nowrap
        input
            height: 20px
            border-radius: 0px
            width: 32px

    &__div2tooltip
        display: flex
        height: 100%

    &__logo
        position: relative
        height: 100%

        border-right: solid 1px $viewer-kolor4

        &--href
            padding: 0 18px
            display: flex
            align-items: center
            height: 100%
            width: 140px
            transition: background 0.15s

            +touch
                padding: 0 8px 0 13px

            &:focus, &:hover, &:active
                img 
                    filter: brightness(0) invert(1)

            &:focus, &:hover
                background: #1A73E8

            &:active
                background: #0E60CB

    &__preview
        height: 100%
        padding-right: 12px
        margin-right: 12px
        margin-left: auto
        border-right: solid 1px $viewer-kolor4

        span
            color: #767676
            line-height: 50px
            vertical-align: middle


    &__how-to-start
        +widescreen
            margin-left: auto

        span
            color: #D0D0D0
            cursor: pointer
            text-decoration: underline

    &__info
        padding: 0 10px
        margin-left: 12px
        height: 100%
        display: flex
        align-items: center
        border-left: solid 1px $viewer-kolor4

        +touch
            padding: 0 8px

        a
            border: none !important

    &__close
        background: $viewer-kolor1
        width: 30px
        height: 30px
        border-radius: 100% !important
        display: flex
        align-items: center
        justify-content: center

        i
            color: white
            font-size: 1rem
            line-height: 18px
            width: 15px

            &:before
                margin: 0
                width: auto

    &__dropdown
        height: 100%
        
        .dropdown-menu
            background: #4C4C4C
            padding: 12px 0px
            position: relative
            border-radius: 0

        .dropdown-item
            color: #FFFFFF
            padding: 5px 22px
            font-size: 0.875rem
            display: flex
            align-items: center

            &:hover, &:focus
                color: #FFFFFF
                background: #1E2026

                span
                    background: #1E2026
                

    &__nav
        border-top: solid 1px $viewer-kolor4
        border-bottom: solid 1px $viewer-kolor4
        padding: 5px 0
        background: $viewer-kolor3
        color: #DDDDDD
        display: flex
        justify-content: space-between
        align-items: center
        height: 60px

        +touch-sec
            padding: 7px 0

        &--left, &--right 
            display: flex
            align-items: center

        &--viewer-layers
            overflow-x: auto
            overflow-y: hidden
            margin-left: 10px
            margin-right: auto

            &::-webkit-scrollbar
                height: 7px
            

            &::-webkit-scrollbar-thumb 
                background: #8e8e8e
                border-radius: 10px
            

        &--right
            padding: 0 9px

            +touch-sec
                overflow-x: auto

            &::-webkit-scrollbar
                height: 7px
            

            &::-webkit-scrollbar-thumb 
                background: #8e8e8e
                border-radius: 10px

            +desktop
                padding: 0 11px

    &__divider
        height: 33px
        width: 1px 
        background: #000
        margin: 0 7px

    &__menu-button
        height: 40px
        padding: 1px 15px
        margin: 0px 20px
        background-color: $viewer-kolor1

        i
            margin-right: 3px

        &::after
            display: none

    &__button
        &--auto-transcribe
            +touch-sec
                display: none !important
            i
                margin-left: -5px

        &--pages
            i.icon-chevron-double-right
                margin-right: -5px

            i.icon-chevron-double-left
                margin-left: -5px

        &--correct-transcription
            display: flex    
            align-items: center
            & > div
                width: 20px
                position: relative
                height: 15px
                i
                    color: inherit

        &--download
            &::after
                display: none

        &--touch
            margin-left: 3px
            +touch
                display: none

    &__text
        &--title
            font-size: 1rem
            margin: 0
            line-height: 1.5rem
            color: #DDDDDD

            +text-elipsis
            
            strong
                color: #B0B0B0
                +mobile
                    display: none

.contrast
    .viewer-header
        &__page-number
            input
                border-color: #ffff00
                background-color: #161615
                color: white
            span
                color: #ffff00

        &__prev-page, &__next-page
            span
                color: #ffff00

        &__how-to-start
            span
                color: #ffff00