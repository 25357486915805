.osd-overlay
    background: rgba(255, 255, 255, 0.01)
    border: 1px solid #707070

    &--drawing
        @extend .osd-overlay
        border: 3px solid $viewer-kolor1

    &--highlight
        @extend .osd-overlay
        border: 3px solid $viewer-kolor1
        &.h-disabled
            
            pointer-events: none

    &--disabled
        pointer-events: none

.osd-drawing
    cursor: nwse-resize

.versenumber
    position: absolute
    left: -3px
    top: -22px
    height: 20px
    padding-left: 4px
    padding-right: 4px
    background-color: $viewer-kolor1

    span
        color: #FFFFFF
        display: block
        margin-top: -1px
        font-size: 12px


.leftbottom, .righttop
    width: 8px
    height: 8px
    display: block
    border: 2px solid $viewer-kolor1
    background-color: $viewer-kolor1
    position: absolute
    cursor: ne-resize
.inner-handler
    width: 100%
    height: 100%
    cursor: move

.leftbottom
    left: -5px
    bottom: -5px

.righttop
    right: -5px
    top: -5px
