.error-link
    color: #005CA9
    background: none
    text-decoration: none

    &:hover,
    &:active
        text-decoration: underline
        color: #005CA9 !important
        background: none !important
    
.nopage
    &--content
        margin-top: 100px
    &--links
        margin-top: 20px
        
        & > p
            display: inline

    &--warning
        color: #CB2E25

\:global(.contrast)
    .nopage
        &--links
            color: #FFF