@import "@Styles/_breakpoints.sass"


.layers
    &__container
        display: flex
        flex-direction: row
        align-items: center
        font-size: 0.9375rem
        margin: 10px

        &__title
            padding-right: 10px
            white-space: nowrap
        
        &__wrapper
            display: flex
            justify-content: space-between
            white-space: nowrap
        
            &--input
                cursor: pointer
                margin-right: 5px 
                
            &--label
                margin-right: auto
                margin-bottom: 0
                cursor: pointer 

            &--hide-btn
                font-size: 0.9375rem
                background-color: transparent
                border: none
                cursor: pointer 
                padding: 0
                margin-left: 5px
                margin-right: 15px
                padding-bottom: 0.2rem
                color: #B0B0B0
                text-decoration: underline
                box-shadow: none !important

                &:focus, &:active, &:hover
                    border: none
                    color: #B0B0B0 !important
                    background-color: transparent !important

                &:disabled
                    background-color: transparent
                    text-decoration: none

            &--show-single-layer
                background-color: transparent
                border: none
                cursor: pointer 
                padding: 0
                padding-left: 5px
                padding-right: 15px
                padding-bottom: 0.2rem
                color: #7C7C7C
                text-decoration: none !important
                box-shadow: none !important

                &:focus, &:active, &:hover
                    border: none
                    color: #FFFFFF
                    background-color: transparent

                &:disabled
                    color: #FFFFFF
                    background-color: transparent 
                    opacity: 1